import * as React from "react";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import Testimonials from "../../components/testimonials/Testimonials";

const bannerImg = '../../banners/equipment-brokerage_banner.jpg';

//RENDER
export default function EquipmentBrokeragePage() {
    return (
        <Layout pageTitle={'Equipment Brokerage'}>
            <SplashBanner
                title={"Equipment Brokerage"}
                content={`Bespoke solutions tailored to each customer's needs, no matter the request we have the network to help locate & negotiate the best price for equipment across the sector.`}
                backgroundImg={bannerImg}
            />
            {/*Custom Packages*/}
            <section id="equipmentBrokerageIntro">
                <div className="container">
                    <div className="split-content">
                        <div className="module text">
                            <h2>Custom Packages</h2>
                            <p>With access to manufacturing businesses, equipment suppliers & service providers,
                                Performance Energy can piece together custom packages and support the mobilisation of
                                equipment globally.</p>
                            <p>Rigs/Units can be provided fully crewed or unmanned.</p>
                            <a className="button primary arrow-light"
                               href="mailto:equipment-requirement@performance-energy.com" target="_blank" rel="noopener noreferrer">
                                <span>Equipment Requirement?</span>
                            </a>
                        </div>
                        <div className="module media">
                            <img
                                src={"../../article/equipment-brokerage_article.jpg"}
                                alt={'Equipment Brokerage Service'}/>
                        </div>
                    </div>
                </div>
            </section>

            <Testimonials />

        </Layout>
    )
}
